<template>
  <a-modal
    :title="rechargeBack ? '项目反充申请' : '项目消费记录详情'"
    :visible="visible"
    @cancel="handleCancel"
    :width="500"
    :body-style="{
      minHeight: '365px',
      maxHeight: '500px',
      overflowY: 'auto'
    }"
  >
    <!-- deductionType 0按次扣费 1计时扣费 2手工扣费 -->
    <!-- 按次/手工扣费方式 -->
    <template v-if="detail.deductionType !== 1">
      <!-- consumeMode 消费类型 0项目套餐次数 1门票资产 2会员余额 3微信支付 4支付宝支付 -->
      <!-- 0项目套餐次数 1门票资产 -->
      <template v-if="detail.consumeMode === 0 || detail.consumeMode === 1">
        <section class="flex-start">
          <p class="label">门票名称：</p>
          <p>{{ detail.mainOrderDetailProductName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费票号：</p>
          <p>{{ detail.consumeNumber }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">游玩项目：</p>
          <p>{{ detail.productName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费时间：</p>
          <p>{{ detail.createTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费数量：</p>
          <p>{{ detail.consumePrice / 100 }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费详情：</p>
          <p>{{ detail.consumeWayInfo }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费操作人：</p>
          <p>{{ detail.consumeOperatorPersonName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费渠道：</p>
          <p>{{ detail.consumeChannelsText }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费状态：</p>
          <p class="primary">{{ detail.consumeStatusText }}</p>
        </section>
      </template>
      <!-- 2会员余额 -->
      <template v-else-if="detail.consumeMode === 2">
        <!-- 会员余额充值门票消费(存在门票号) -->
        <template v-if="detail.consumeNumber">
          <section class="flex-start">
            <p class="label">门票名称：</p>
            <p>{{ detail.mainOrderDetailProductName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费票号：</p>
            <p>{{ detail.consumeNumber }}</p>
          </section>
        </template>
        <section class="flex-start mt-2x">
          <p class="label">游玩项目：</p>
          <p>{{ detail.productName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费时间：</p>
          <p>{{ detail.createTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费数量：</p>
          <p>{{ detail.consumePrice / 100 }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费详情：</p>
          <p>{{ detail.consumeWayInfo }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">会员信息：</p>
          <p>
            {{ detail.purchaserMemberPhone }} / {{ detail.purchaserMemberName }}
          </p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费操作人：</p>
          <p>{{ detail.consumeOperatorPersonName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费渠道：</p>
          <p>{{ detail.consumeChannelsText }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费状态：</p>
          <p class="primary">{{ detail.consumeStatusText }}</p>
        </section>
      </template>
      <!-- 3微信支付 4支付宝支付 -->
      <template
        v-else-if="detail.consumeMode === 3 || detail.consumeMode === 4"
      >
        <section class="flex-start mt-2x">
          <p class="label">游玩项目：</p>
          <p>{{ detail.productName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费时间：</p>
          <p>{{ detail.createTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费数量：</p>
          <p>{{ detail.consumePrice / 100 }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费详情：</p>
          <p>{{ detail.consumeWayInfo }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">订单编号：</p>
          <p>{{ detail.baseOrderNo }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费操作人：</p>
          <p>{{ detail.consumeOperatorPersonName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费渠道：</p>
          <p>{{ detail.consumeChannelsText }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费状态：</p>
          <p class="primary">{{ detail.consumeStatusText }}</p>
        </section>
      </template>
    </template>
    <!-- 计时扣费方式 -->
    <template v-else>
      <!-- consumeMode 消费类型 0项目套餐次数 1门票资产 2会员余额 3 微信支付 4 支付宝支付 -->
      <template v-if="!detail.playEndTime">
        <section class="flex-start">
          <p class="label">门票名称：</p>
          <p>{{ detail.mainOrderDetailProductName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">消费票号：</p>
          <p>{{ detail.consumeNumber }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">游玩项目：</p>
          <p>{{ detail.productName }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">游玩开始时间：</p>
          <p>{{ detail.playBeginTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
        </section>
        <section class="flex-start mt-2x">
          <p class="label">押金：</p>
          <p>{{ detail.depositInfo }}</p>
        </section>
      </template>
      <template v-else>
        <!-- 1门票资产 -->
        <template v-if="detail.consumeMode === 1">
          <section class="flex-start">
            <p class="label">门票名称：</p>
            <p>{{ detail.mainOrderDetailProductName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费票号：</p>
            <p>{{ detail.consumeNumber }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">游玩项目：</p>
            <p>{{ detail.productName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">游玩开始时间：</p>
            <p>{{ detail.playBeginTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">押金：</p>
            <p>{{ detail.depositInfo }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">游玩结束时间：</p>
            <p>{{ detail.playEndTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">游玩时长：</p>
            <p>{{ detail.playEndTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">应收：</p>
            <p>{{ detail.consumePrice | money }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">收费详情：</p>
            <p>{{ detail.consumeWayInfo }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">会员信息：</p>
            <p>
              {{ detail.purchaserMemberPhone }} /
              {{ detail.purchaserMemberName }}
            </p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费操作人：</p>
            <p>{{ detail.consumeOperatorPersonName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费渠道：</p>
            <p>{{ detail.consumeChannelsText }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费状态：</p>
            <p class="primary">{{ detail.consumeStatusText }}</p>
          </section>
        </template>
        <!-- 2会员余额 -->
        <template v-else-if="detail.consumeMode === 2">
          <!-- 会员余额充值门票消费(存在门票号) -->
          <template v-if="detail.consumeNumber">
            <section class="flex-start">
              <p class="label">门票名称：</p>
              <p>{{ detail.mainOrderDetailProductName }}</p>
            </section>
            <section class="flex-start mt-2x">
              <p class="label">消费票号：</p>
              <p>{{ detail.consumeNumber }}</p>
            </section>
          </template>
          <section class="flex-start mt-2x">
            <p class="label">游玩项目：</p>
            <p>{{ detail.productName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费时间：</p>
            <p>{{ detail.playEndTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费数量：</p>
            <p>{{ detail.playEndTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费详情：</p>
            <p>{{ detail.consumeWayInfo }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">会员信息：</p>
            <p>
              {{ detail.purchaserMemberPhone }} /
              {{ detail.purchaserMemberName }}
            </p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费操作人：</p>
            <p>{{ detail.consumeOperatorPersonName }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费渠道：</p>
            <p>{{ detail.consumeChannelsText }}</p>
          </section>
          <section class="flex-start mt-2x">
            <p class="label">消费状态：</p>
            <p class="primary">{{ detail.consumeStatusText }}</p>
          </section>
        </template>
      </template>
    </template>
    <!-- 反充内容 -->
    <template v-if="detail.backConsumePersonName">
      <section class="flex-start mt-2x">
        <p class="label">反充申请时间：</p>
        <p>{{ detail.backConsumeApplyTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
      </section>
      <section class="flex-start mt-2x">
        <p class="label">反充申请人：</p>
        <p>{{ detail.backConsumePersonName }}</p>
      </section>
      <section class="flex-start mt-2x">
        <p class="label">反充申请渠道：</p>
        <p>{{ detail.backConsumeChannelsText }}</p>
      </section>
      <section class="flex-start mt-2x">
        <p class="label">反充申请说明：</p>
        <p>{{ detail.backConsumeRemarks }}</p>
      </section>
      <section class="flex-start mt-2x">
        <p class="label">反充审核时间：</p>
        <p>{{ detail.backConsumeExamineTime | date("YYYY-MM-DD hh:mm:ss") }}</p>
      </section>
      <section class="flex-start mt-2x">
        <p class="label">反充审核人：</p>
        <p>{{ detail.backConsumeOperatorPersonName }}</p>
      </section>
    </template>
    <!-- 反充说明表单 -->
    <a-form
      v-if="rechargeBack"
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      label-align="left"
      class="mt-2x"
    >
      <a-form-item label="申请说明">
        <a-input
          placeholder="请输入申请说明"
          v-decorator="[
            'remark',
            {
              rules: [
                { required: true, message: '请输入申请说明' },
                { required: true, validator: checkText }
              ]
            }
          ]"
        />
      </a-form-item>
    </a-form>
    <!-- 底部按钮 -->
    <footer slot="footer">
      <a-button @click="handleCancel">{{
        rechargeBack ? "取消" : "关闭"
      }}</a-button>
      <a-button
        @click="handleOk"
        type="primary"
        v-if="rechargeBack"
        :loading="loading"
        >提交申请</a-button
      >
    </footer>
  </a-modal>
</template>

<script>
import { checkText } from "@/utils/global";
import { getItemWriteOff, itemwriteOffBack } from "@/api/order";

export default {
  name: "RecordDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ""
    },
    rechargeBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkText,
      detail: {},
      form: this.$form.createForm(this, { name: "remarkForm" }),
      loading: false
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) this.loadData();
    }
  },
  methods: {
    loadData() {
      this.detail = {};
      getItemWriteOff({
        id: this.id // 核销记录id
      })
        .then(res => {
          this.detail = res;
        })
        .catch(() => {});
    },
    // 关闭
    handleCancel() {
      if (this.loading) return;
      this.$emit("onClose", false);
    },
    // 确定
    handleOk() {
      this.form.validateFields(err => {
        if (!err) {
          this.loading = true;
          itemwriteOffBack({
            id: this.id // 核销记录id
          })
            .then(() => {
              this.$message.success("提交成功");
              this.loading = false;
              this.$emit("onOk", true);
              this.handleCancel();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.label {
  width: 100px;
}
</style>
