<template>
  <div class="wrapper flex-between flex-col">
    <!-- 头部 -->
    <panel-head title="结束计时"></panel-head>
    <!-- 键盘 -->
    <section class="main mt-2x">
      <keyboard-pro type="2" v-model="num" @ok="onOk">
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              size="large"
              placeholder="票号/卡或腕带号"
              v-model="num"
              allow-clear
              @pressEnter="confirm()"
            >
              <a-icon slot="prefix" type="search" />
              <a-icon type="scan" slot="suffix" />
            </a-input>
          </a-form-model>
        </template>
      </keyboard-pro>
    </section>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        type="primary"
        class="btn-lg"
        ghost
        size="large"
        shape="round"
        @click="$router.back()"
        >返回</a-button
      >
      <a-button
        type="primary"
        size="large"
        shape="round"
        class="btn-lg ml-3x"
        @click="toRecord"
        >查看全部未结算记录</a-button
      >
    </footer>
    <!-- 输入会员支付密码弹窗 -->
    <a-modal
      v-model="passwordFormVisible"
      title="请输入会员支付密码"
      @ok="onOk"
      @cancel="resetPasswordForm"
    >
      <a-form-model
        ref="passwordForm"
        :model="passwordForm"
        :rules="passwordFormRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        label-align="left"
      >
        <a-form-model-item label="会员姓名" :colon="false">
          <span>{{ memberInfo.name }}</span>
        </a-form-model-item>
        <a-form-model-item label="会员手机号" :colon="false">
          <span>{{ memberInfo.mobile }}</span>
        </a-form-model-item>
        <a-form-model-item label="支付密码" prop="password" :colon="false">
          <a-input-password
            v-model="passwordForm.password"
            placeholder="请输入6位纯数字支付密码"
            :max-length="6"
            allow-clear
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import KeyboardPro from "../../../components/common/KeyboardPro";
import { mapState } from "vuex";
import { itemWriteoff, itemConsumerMember } from "@/api/order";
import { dateFormat } from "@/utils/global";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";

export default {
  name: "DeductionTimingOff",
  components: { PanelHead, KeyboardPro },
  data() {
    // 自定义校验设置支付密码
    let checkPassword = (rule, value, callback) => {
      if (value !== "") {
        if (!/^[0-9]{6}$/.test(value)) {
          callback(new Error("请输入6位纯数字支付密码"));
        } else {
          callback();
        }
      }
    };

    return {
      num: "", // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
      passwordFormVisible: false, // 是否显示输入会员支付密码弹窗
      passwordForm: {
        password: ""
      },
      passwordFormRules: {
        password: [{ validator: checkPassword, trigger: "blur" }]
      },
      memberInfo: {} // 会员信息
    };
  },
  computed: {
    ...mapState("consume", ["playItem"])
  },
  methods: {
    // 通过项目消费编号获取会员信息
    getMemberInfo() {
      itemConsumerMember({ consumeNumber: this.num })
        .then(res => {
          this.memberInfo = res;
        })
        .catch(() => {});
    },

    onOk() {
      // this.$refs.passwordForm.validate(valid => {
      //   if (valid && this.num) {
      let { playItem, num } = this;
      let params = {
        consumeChannels: "6", // 消费渠道
        num: num, // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
        itemName: playItem.name, // 项目名称
        itemSkuId: playItem.skuId, // 项目skuId
        itemSpuId: playItem.spuId, // 项目spuId
        timeType: 1 // 计时类型：开始0 结束1
      };
      // 如果是会员码消费，则需要输入会员密码
      if (this.passwordForm.password !== "") {
        params.memberPw = this.passwordForm.password; // 会员密码
      }
      itemWriteoff(params, { catch: false })
        .then(res => {
          this.passwordFormVisible = false;
          HardwareService.speak(`消费${res.title}`);
          let obj = {
            ticketInfo: "门票",
            memberInfo: "会员",
            depositInfo: "押金",
            beginTime: "开始时间",
            entTime: "结束时间",
            playDuration: "游玩时长",
            receivable: "应收",
            receivableInfo: "收费详情",
            assets: "剩余"
          };
          let getNode = data => {
            let arr = [];
            for (const key in data) {
              if (obj[key] && data[key] !== null) {
                arr.push(
                  <p class="mt-1x">
                    {obj[key]}：{dateFormat(data[key], "YYYY-MM-DD hh:mm:ss")}
                  </p>
                );
              }
            }
            return <div>{...arr}</div>;
          };
          this.$notification.success({
            top: "60px",
            message: res.title,
            description: getNode(res)
          });
          // 重置部分表单
          this.num = "";
          this.passwordForm.password = "";
        })
        .catch(err => {
          let errMsg = ""; // 错误消息
          if (err.status === "MEMBER10140") {
            this.getMemberInfo();
            this.passwordFormVisible = true;
            errMsg = err.message;
            HardwareService.speak(`${errMsg}`);
          } else {
            errMsg =
              err.message.indexOf(",") > 0
                ? err.message.substring(0, err.message.indexOf(","))
                : err.message;
            HardwareService.speak(`消费失败，${errMsg}`);
          }
          this.$notification.error({
            top: "60px",
            message: `消费失败`,
            description: h => {
              const arr = [h("p", { class: "mt-1x" }, `${errMsg}`)];
              return h("div", arr);
            }
          });
        });
      // } else {
      //   this.$message.warning("验证不通过，请重新检查");
      //   return false;
      // }
      // });
    },

    // 重置会员支付密码表单
    resetPasswordForm() {
      this.$refs.passwordForm.resetFields();
    },

    toRecord() {
      this.$router.push({
        path: "/play/deduction/DeductionTimingRecord",
        query: { consumeNum: this.num }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-top: 60px;
}
</style>
