<template>
  <div class="wrapper pb-2x flex-start flex-col">
    <panel-head :close="false" title="门票查询"></panel-head>
    <div
      class="container mt-2x flex-grow"
      v-permission="'appPlayTicketRechargeTicketinquiry'"
    >
      <KeyboardPro @ok="ok" v-model="numbers">
        <template v-slot:input="{ confirm }">
          <a-input
            size="large"
            allow-clear
            v-model="numbers"
            placeholder="票号/卡或腕带号"
            @pressEnter="confirm()"
          >
            <a-icon
              class="primary"
              slot="prefix"
              type="search"
              style="font-size:24px"
            />
            <a-icon
              class="primary"
              slot="suffix"
              type="scan"
              style="font-size: 24px"
            />
          </a-input>
        </template>
      </KeyboardPro>
    </div>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "../../../components/common/KeyboardPro.vue";
import { ticketRecharge } from "../../../api/cart";

import { clearScanCode, scanCode } from "../../../utils/hardware";

export default {
  components: { PanelHead, KeyboardPro },
  name: "TicketSearch",
  data() {
    return {
      numbers: ""
    };
  },
  mounted() {
    scanCode(this.scanCode);
  },

  beforeDestroy() {
    clearScanCode();
  },

  methods: {
    scanCode(res) {
      this.numbers = res;
      this.ok();
    },
    ok() {
      if (!this.numbers) {
        this.$message.warning("请先输入票号或卡腕带号");
        return;
      }
      ticketRecharge({
        ticketNo: this.numbers
      })
        .then(res => {
          this.$router.push({
            path: "/play/recharge/ticketRecharge",
            query: {
              ticketNo: res.ticketNo,
              id: res.id,
              ticketDetailId: res.ticketDetailId,
              ticketName: res.ticketName
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding-top: 60px;
}
</style>
