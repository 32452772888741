<!-- 门票充值 弹窗 -->

<template>
  <div class="wrapper flex-between flex-col">
    <div class="header">
      <div class="flex-start align-center">
        <p class="font-xl">门票充值</p>
        <div class="text-card">票号：{{ ticketNo }}</div>
        <div class="text-card last">门票名称：{{ ticketName }}</div>
        <a-button size="large" type="link" @click="toTicketDetail">
          门票详情
        </a-button>
      </div>
    </div>
    <!-- 选项卡切换列表 -->
    <div class="main">
      <a-tabs type="card">
        <!-- 门票资产套餐 tab -->
        <a-tab-pane key="1" tab="门票资产套餐">
          <!-- <ul class="flex-wrap ul"> -->
          <a-row :gutter="[24, 24]">
            <template v-if="tikectData.length">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in tikectData"
                :key="index"
              >
                <div class="ticket-card">
                  <p class="mt-1x card-title bold">{{ item.name }}</p>
                  <div class="mv-4x text-1 font-xl" v-if="item.assetsInfoList">
                    含：{{
                      item.assetsInfoList.length
                        ? item.assetsInfoList.toString()
                        : ""
                    }}
                  </div>
                  <!-- 计算器 -->
                  <div class="flex-between align-center">
                    <span class="price-style primary-liner">{{
                      item.sellingPrice | money
                    }}</span>

                    <div class="quantity-style flex-start">
                      <div class="minus-style" @click="minusClick('1', index)">
                        <a-icon type="minus" />
                      </div>
                      <a-input
                        class="num-style"
                        v-model="item.count"
                        @blur="inputBlur('1', item.count)"
                      />
                      <div class="plus-style" @click="plusClick('1', index)">
                        <a-icon type="plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </template>
            <div v-else class="flex-center res">
              <a-empty />
            </div>
          </a-row>
        </a-tab-pane>
        <!-- 项目套餐 tab -->
        <a-tab-pane key="2" tab="项目套餐">
          <a-row :gutter="[24, 24]">
            <template v-if="tikectData2.length">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in tikectData2"
                :key="index"
              >
                <div class="ticket-card">
                  <p class="mt-1x card-title bold">{{ item.name }}</p>
                  <p class="mv-2x font-xl text-1">
                    可玩次数：
                    <span v-if="item.spuSpecInfo.whetherUseLimitType">
                      {{ item.spuSpecInfo ? item.spuSpecInfo.useLimit : "" }}
                      次
                    </span>
                    <span v-else> 不限 </span>
                  </p>
                  <div class="mb-2x font-xl text-1">
                    可玩项目：
                    <span v-if="item.spuSpecInfo.relationProjectType == 1">
                      全部
                    </span>
                    <span v-if="item.spuSpecInfo.relationProjectType == 2">
                      {{ relateList(item.projectInfoList).substr(0, 6) }}
                    </span>
                    <a-popover
                      trigger="hover"
                      class="ml-x"
                      v-if="item.spuSpecInfo.relationProjectType == 2"
                    >
                      <template slot="content" v-if="item.projectInfoList">
                        <p
                          v-for="(item2, index) in item.projectInfoList"
                          :key="index"
                        >
                          {{ item2.name }}
                          <span class="ml-2x">可玩次数：</span>
                          <span
                            v-if="
                              item2.customRelation &&
                                item2.customRelation.lessLimit
                            "
                          >
                            最少：{{ item2.customRelation.lessLimit }}次
                          </span>
                          <span v-else> 不限 </span>
                          <span
                            v-if="
                              item2.customRelation &&
                                item2.customRelation.mostLimit
                            "
                          >
                            最多：{{ item2.customRelation.mostLimit }}次
                          </span>
                          <span v-else> 不限 </span>
                        </p>
                      </template>
                      <a-icon class="icon pointer" type="ellipsis" />
                    </a-popover>
                  </div>
                  <!-- 计算器 -->
                  <div class="flex-between align-center calculator">
                    <span class="price-style primary-liner">{{
                      item.sellingPrice | money
                    }}</span>
                    <!-- 步进器 -->
                    <div class="quantity-style">
                      <div class="minus-style" @click="minusClick('2', index)">
                        <a-icon type="minus" />
                      </div>
                      <a-input
                        class="num-style"
                        v-model="item.count"
                        @blur="inputBlur('2', item.count)"
                      />
                      <div class="plus-style" @click="plusClick('2', index)">
                        <a-icon type="plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </template>
            <div v-else class="flex-center res">
              <a-empty />
            </div>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 应收金额 -->
    <div class="footer">
      <a-button
        type="primary"
        ghost
        class="btn-lg"
        size="large"
        shape="round"
        @click="cancel"
      >
        取消充值
      </a-button>
      <a-button
        type="primary"
        class="btn-lg flex-between align-center ml-3x"
        size="large"
        shape="round"
        @click="confirm"
      >
        <span>结账</span>
        <span>{{ totalMoney | money }}</span>
      </a-button>
    </div>
  </div>
</template>
<script>
import { productFrontRechargeticket } from "@/api/search";
import { manyProduct } from "@/api/cart";
import { mapActions } from "vuex";
import { orderSonmainauxiliaryid } from "../../../api/search";

export default {
  name: "TicketRecharge",
  components: {},
  data() {
    return {
      detail: {
        ticketNo: this.$route.query.ticketNo
      },
      ticketName: "",
      ticketNo: "",
      ticketDetailId: "", //门票编号
      tikectData: [], // 门票资产套餐数据
      tikectData2: [], // 项目套餐数据
      calcAmountTotal1: null, // 资产门票套餐总额
      calcAmountTotal2: null, // 资产门票套餐总额
      totalMoney: "", // 所有应收总额
      carId: "" // 购物车订单id
    };
  },
  watch: {},
  created() {
    this.carId = this.$route.query.id;
    this.ticketDetailId = this.$route.query.ticketDetailId;
    this.ticketNo = this.$route.query.ticketNo;
    this.ticketName = this.$route.query.ticketName;
    Promise.all([this.getData(1), this.getData(2)]).then(res => {
      // console.log(res, "门票充值数据");
      this.tikectData = res[0];
      this.tikectData2 = res[1];
      // this.getCartId();
    });
  },
  methods: {
    ...mapActions("order", ["setCartList"]),
    getData(type) {
      return new Promise(resolve => {
        productFrontRechargeticket({
          packageType: type || 1, // 充值套餐类型 1: 门票资产套餐 2: 项目套餐
          page: 1,
          size: 9999
        })
          .then(res => {
            resolve(res.records);
          })
          .catch(() => {});
      });
    },
    // // 生成购物车id
    // getCartId() {
    //   ticketRecharge({
    //     ticketNo: this.detail.ticketNo
    //   })
    //     .then(res => {
    //       this.carId = res.id;
    //       this.ticketDetailId = res.ticketDetailId;
    //       this.ticketName = res.ticketName;
    //       this.ticketNo = res.ticketNo;
    //     })
    //     .catch(() => {});
    // },
    // 可玩项目字符截取
    relateList(params) {
      const arr = [];
      if (params) {
        params.forEach(item => {
          arr.push(item.name);
        });
      }
      return arr.toString();
    },
    // 计算器 增加 按钮
    plusClick(type, index) {
      this.add({
        plusParam: type == 1 ? this.tikectData[index] : this.tikectData2[index], // 用于计算器加减数
        tikectData: type == 1 ? this.tikectData : this.tikectData2, // 用于计算应收总额
        type // 用于计算应收总额
      });
    },
    // 计算器 减少 按钮
    minusClick(type, index) {
      this.minus({
        plusParam: type == 1 ? this.tikectData[index] : this.tikectData2[index], // 用于计算器加减数
        tikectData: type == 1 ? this.tikectData : this.tikectData2,
        type
      });
    },
    // input blur
    inputBlur(type, value) {
      if (!/(^[0-9]\d*$)/.test(value)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      // type == 1 门票资产套餐TAB
      this.calcAmount({
        tikectData: type == 1 ? this.tikectData : this.tikectData2,
        type
      });
    },

    // 计算应收金额
    /**
     * 遍历每张票的价格乘以计算器的数值，得到每张票的总额，
     * 然后再遍历汇总所有门票的金额
     */
    calcAmount(param) {
      let mon = "";
      mon = param.tikectData
        .map((item, index) => {
          // 先计算每张票的额度
          return item.sellingPrice * param.tikectData[index].count;
        })
        .reduce((prev, cur) => {
          // 再计算所有票的总额
          return prev + cur;
        });
      if (param.type == 1) {
        this.calcAmountTotal1 = mon;
      } else {
        this.calcAmountTotal2 = mon;
      }
      this.totalMoney = this.calcAmountTotal1 + this.calcAmountTotal2;
    },

    // 计算器加减方法抽离(arr:每个计算器加减的数据)
    add(params) {
      if (!/(^[0-9]\d*$)/.test(params.plusParam.count)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      params.plusParam.count++;
      this.calcAmount(params);
    },
    // 计算器减少方法抽离
    minus(params) {
      if (!/(^[0-9]\d*$)/.test(params.plusParam.count)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      if (params.plusParam.count <= 0) {
        this.$message.warning("已经是最小值了");
        return;
      }
      params.plusParam.count--;
      this.calcAmount(params);
    },

    // 关闭弹窗、及取消按钮
    cancel() {
      this.$router.go(-1);
    },

    // 确定按钮
    confirm() {
      const arr = [...this.tikectData, ...this.tikectData2];
      let flag = false;
      const arr2 = arr.filter(item => {
        item.totalMoney = this.totalMoney;
        if (!item.count.toString()) {
          flag = true;
        }
        return item.count >= 1;
      });
      if (flag) {
        this.$message.warning("你还有套餐价格未输入");
        return;
      }
      if (arr2.length <= 0) {
        this.$message.warning("你还未选择套餐和套餐数量");
        return;
      }
      const params = {
        id: this.carId, // 生成的购物id
        buyChannels: 6, // 购买渠道
        businessCategory: 4, // 订单业务类型
        productList: []
      };
      arr2.forEach(item => {
        params.productList.push({
          productId: item.id,
          spuId: item.spuId,
          buyNum: item.count
        });
      });
      manyProduct(params)
        .then(res => {
          this.setCartList(res);
          //设置支付后回跳的地址
          this.$store.commit("order/setEndOfPayConfig", {
            backUrl: "/play/recharge/ticketSearch",
            logoutMember: true
          });
          this.$router.push({ path: "/home/sell/OrderInfo" });
        })
        .catch(() => {});
    },
    // 去门票详情
    toTicketDetail() {
      // 根据子票号获取主票号
      orderSonmainauxiliaryid({
        auxiliaryId: this.ticketNo
      })
        .then(({ id }) => {
          this.$router.push({
            path: "/home/ticket/TicketOperateDetail",
            query: { id, isRetail: "1" }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

// 头部
.text-card {
  padding: 0 24px;
  line-height: 54px;
  font-size: 18px;
  background: #f4f9fc;
  margin-left: 40px;
  &.last {
    margin-right: 40px;
  }
}

/*中部*/
.main {
  padding: 16px;
  margin: 10px 0;
  height: 530px;
  .ticket-card {
    padding: 12px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: #f4f9fc;
    .card-title {
      font-size: 28px;
    }
    .icon {
      background: #c8ccce;
      border-radius: 50%;
      color: #fff;
      padding: 1px;
      font-size: 16px;
    }
  }
}

// 价格样式
.price-style {
  font-size: 28px;
  font-weight: bold;
}
</style>
