<template>
  <div class="summary-total wrapper flex-col flex-start">
    <!-- 头部 -->
    <panel-head title="消费汇总"></panel-head>
    <!-- 主体 -->
    <div class="main flex-start mt-2x">
      <div class="consume-statistics flex-shrink bd-r pb-2x">
        <!-- 查询 -->
        <a-form-model
          class="p-2x"
          layout="inline"
          ref="searchForm"
          :model="searchForm"
        >
          <a-form-model-item class="m-0" label="消费日期">
            <a-range-picker
              class="input"
              :allow-clear="false"
              :default-value="[
                moment(searchForm.startDate),
                moment(searchForm.endDate)
              ]"
              @change="dateChange"
            />
          </a-form-model-item>
        </a-form-model>
        <div
          v-for="(item, index) in receptionNumList"
          :key="index"
          class="consume-box"
        >
          <div
            class="flex-col flex-start ph-1x"
            :class="{ active: baseId === item.id }"
            @click="selectItem(item)"
          >
            <div class="flex-start flex-grow mb-1x">
              <span class="flex-grow">{{ item.collectTime | date }}</span>
              <span class="flex-grow">{{ item.productName }}</span>
            </div>
            <span class="flex-grow font-l">
              接待人次：{{ item.receptionNum }}
            </span>
          </div>
        </div>

        <a-pagination
          v-model="current"
          :default-page-size="pageSize"
          :total="total"
          class="pagination"
          @change="paginationChange"
          show-less-items
        />
      </div>
      <a-tabs
        type="card"
        class="flex-grow"
        default-active-key="1"
        @change="changeTab"
      >
        <a-tab-pane key="1" tab="消费类型汇总">
          <!-- 消费类型汇总表格 -->
          <a-table
            class="ph-1x"
            :columns="consumeTypeTitle"
            :data-source="consumeTypeList"
            :pagination="false"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="count" slot-scope="row">
              {{ row / 100 }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="项目套餐消费汇总">
          <!-- 项目套餐消费汇总表格 -->
          <a-table
            class="ph-1x"
            :columns="packageConsumeTitle"
            :data-source="packageConsumeList"
            :pagination="false"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        type="primary"
        size="large"
        class="btn-lg"
        ghost
        shape="round"
        @click="$router.back()"
        >返回</a-button
      >
    </div>
  </div>
</template>

<script>
import {
  listOrderItemCollectBaseInfo,
  listOrderItemConsumeTypeCollectInfo,
  listOrderItemPackageConsumeCollectInfo
} from "@/api/order.js"; // MS-畅游宝订单中心接口
import PanelHead from "@/components/common/PanelHead.vue";
import { mapState } from "vuex";
import moment from "moment";

// 消费类型汇总表头
const consumeTypeTitle = [
  {
    title: "消费类型",
    dataIndex: "consumeTypeName"
  },
  {
    title: "消费数量",
    dataIndex: "consumeNum",
    scopedSlots: { customRender: "count" }
  },
  {
    title: "反充数量",
    dataIndex: "backConsumeNum",
    scopedSlots: { customRender: "count" }
  },
  {
    title: "数量小计",
    dataIndex: "totalConsumeNum",
    scopedSlots: { customRender: "count" }
  }
];

// 项目套餐消费汇总表头
const packageConsumeTitle = [
  {
    title: "产品来源",
    dataIndex: "orderSourceTypeText"
  },
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "产品分类",
    dataIndex: "productCategoryIdText"
  },
  {
    title: "消费数量",
    dataIndex: "consumeNum"
  },
  {
    title: "反充数量",
    dataIndex: "backConsumeNum"
  },
  {
    title: "数量小计",
    dataIndex: "totalConsumeNum"
  }
];

export default {
  name: "SummaryTotal",
  components: {
    PanelHead
  },
  data() {
    return {
      moment,
      consumeTypeList: [], // 项目套餐消费汇总列表
      // 消费汇总人次列表接口参数
      interFaceParams: {
        page: 1,
        size: 6
      },
      // 查询表单
      searchForm: {
        startDate: moment()
          .subtract(6, "days")
          .startOf("date")
          .valueOf(), // 开始日期
        endDate: moment()
          .endOf("date")
          .valueOf() // 结束日期
      },
      currPage: 1, // 当前页数
      // 分页参数
      // pagination: {
      //   onChange: page => {
      //     this.currPage = page;
      //   },
      //   pageSize: 6,
      //   showLessItems: true
      // },
      baseId: "", // 消费汇总基础id
      receptionNumList: [], // 消费汇总人次列表
      consumeTypeTitle, // 项目套餐消费汇总表头
      packageConsumeTitle, // 项目套餐消费汇总表头
      packageConsumeList: [], // 项目套餐消费汇总列表
      current: 1, //列表页码
      pageSize: 6,
      total: 0 //列表总页数
    };
  },
  computed: mapState("consume", ["playItem"]),
  created() {
    this.getReceptionNumList(); // 查询消费汇总人次列表
  },
  methods: {
    // 查询消费汇总人次列表
    getReceptionNumList() {
      this.interFaceParams.productId = this.playItem.skuId; // 项目id
      this.interFaceParams.startDate = this.searchForm.startDate; // 开始日期
      this.interFaceParams.endDate = this.searchForm.endDate; // 结束日期
      this.interFaceParams.page = this.current; // 当前页码
      this.interFaceParams.size = this.pageSize; // 每页条数
      listOrderItemCollectBaseInfo(this.interFaceParams)
        .then(res => {
          this.receptionNumList = res.records || [];
          this.baseId = res.records[0].id; // 消费汇总基础id
          this.total = res.total; // 总条数
          if (this.baseId) {
            this.getConsumeTypeList(); // 根据项目汇总基础id查询消费类型汇总列表
          }
        })
        .catch(() => {});
    },

    // 查询消费类型汇总列表
    getConsumeTypeList() {
      listOrderItemConsumeTypeCollectInfo({
        itemCollectBaseInfoId: this.baseId // 消费汇总基础id
      })
        .then(res => {
          this.consumeTypeList = res.records || [];
        })
        .catch(() => {});
    },

    // 根据项目汇总基础id查询项目套餐消费汇总列表
    getPlayItemPackageList() {
      listOrderItemPackageConsumeCollectInfo({
        itemCollectBaseInfoId: this.baseId // 消费汇总基础id
      })
        .then(res => {
          this.packageConsumeList = res.records || [];
          // 表格增加一行：合计
          if (this.packageConsumeList.length) {
            this.addRowCalc();
          }
        })
        .catch(() => {});
    },
    //点击页码
    paginationChange() {
      this.getReceptionNumList();
    },
    // tab切换
    changeTab(key) {
      switch (key) {
        case "1":
          if (this.baseId) {
            this.getConsumeTypeList(); // 根据项目汇总基础id查询消费类型汇总列表
          }
          break;
        case "2":
          if (this.baseId) {
            this.getPlayItemPackageList(); // 根据项目汇总基础id查询项目套餐消费汇总列表
          }
          break;
        default:
          this.$message.warning("出现未知错误，请联系管理员");
          break;
      }
    },

    // 表格增加一行：合计
    addRowCalc() {
      this.packageConsumeList.push({
        productName: "合计", // 产品名称
        consumeNum: this.calcAmount("consumeNum"), // 消费数量
        backConsumeNum: this.calcAmount("backConsumeNum"), // 反充数量
        totalConsumeNum: this.calcAmount("totalConsumeNum") // 总消费数量
      });
    },

    // 表格求和
    calcAmount(param) {
      return this.packageConsumeList.reduce((pre, cur) => {
        return pre + parseFloat(cur[param]);
      }, 0);
    },

    // 日期选择
    dateChange([start, end]) {
      if (start && end) {
        this.searchForm.startDate = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.endDate = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.startDate = "";
        this.searchForm.endDate = "";
      }
      this.interFaceParams.startDate = this.searchForm.startDate; // 开始日期
      this.interFaceParams.endDate = this.searchForm.endDate; // 结束日期
      this.getReceptionNumList();
    },

    // 选择项目
    selectItem(item) {
      this.baseId = item.id; // 变更消费汇总基础id
      this.getConsumeTypeList();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.input {
  width: 200px;
}

.summary-total {
  .main {
    height: 100%;
    .consume-statistics {
      width: 304px;
      height: 100%;
    }
  }
}

.consume-box > div {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.active {
  color: #fff;
  background: @primary;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}
</style>
