<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="消费记录"></panel-head>
    <!--body-->
    <section class="main mt-2x">
      <!-- 查询 -->
      <div class="p-2x">
        <a-form-model layout="inline" :model="searchForm" ref="searchForm">
          <a-form-model-item label="游玩日期">
            <a-range-picker
              :allow-clear="false"
              class="input"
              :default-value="[
                moment(searchForm.playBeginTime),
                moment(searchForm.playEndTime)
              ]"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <a-input
              v-model="searchForm.ticketNo"
              placeholder="请输入票号"
              class="input"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="消费状态" prop="status">
            <a-select
              showSearch
              class="input"
              v-model="searchForm.status"
              placeholder="请选择消费状态"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option :value="0">正常</a-select-option>
              <a-select-option :value="1">反充未审批</a-select-option>
              <a-select-option :value="2">反充通过</a-select-option>
              <a-select-option :value="3">未反充通过</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="reload">查询</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="reset('searchForm')">重置</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="link" size="large" @click="searchVisible = true"
              >高级查询</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 高级查询 -->
      <a-drawer
        title="高级查询"
        placement="right"
        :width="400"
        :closable="false"
        :visible="searchVisible"
        @close="searchVisible = false"
      >
        <a-form-model
          ref="heightForm"
          :model="searchForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item label="游玩日期">
            <a-range-picker
              :allow-clear="false"
              :default-value="[
                moment(searchForm.playBeginTime),
                moment(searchForm.playEndTime)
              ]"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <a-input
              v-model="searchForm.ticketNo"
              placeholder="请输入票号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="消费状态" prop="status">
            <a-select
              showSearch
              v-model="searchForm.status"
              placeholder="请选择消费状态"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in consumeStatusList"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="会员号码" prop="purchaserMemberId">
            <a-input
              v-model="searchForm.purchaserMemberId"
              placeholder="请输入会员号码"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="销售渠道" prop="consumeChannel">
            <a-select
              v-model="searchForm.consumeChannel"
              placeholder="请选择销售渠道"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in buyChannel"
                :key="index"
                :value="item.id"
              >
                {{ item.channelName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="项目套餐消费" prop="projectConsume">
            <a-switch v-model="searchForm.projectConsume" />
          </a-form-model-item>
          <a-form-model-item label="付款码消费" prop="payConsume">
            <a-switch v-model="searchForm.payConsume" />
          </a-form-model-item>
          <a-form-model-item label="会员余额消费" prop="memberConsume">
            <a-switch v-model="searchForm.memberConsume" />
          </a-form-model-item>
          <a-form-model-item label="门票资产消费" prop="ticketConsume">
            <a-select
              v-model="searchForm.ticketConsume"
              placeholder="请选门票资产消费"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in ticketConsumeList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="操作人" prop="operatorPersonIds">
            <a-select
              allow-clear
              mode="multiple"
              showSearch
              optionFilterProp="children"
              v-model="searchForm.operatorPersonIds"
              placeholder="请选择操作人"
            >
              <a-select-option
                v-for="(item, index) in operatorList"
                :key="index"
                :value="item.userId"
              >
                {{ item.userName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false">
            <a-button type="primary" class="mr-2x" @click="reload"
              >查询</a-button
            >
            <a-button @click="reset('heightForm')">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
      <!-- 表格列表 -->
      <a-table
        :columns="tableTitle"
        :data-source="tableData"
        :pagination="false"
        row-key="id"
      >
        <template slot="productName" slot-scope="row">
          {{ row.consumeNumber }} / {{ row.productName }}
        </template>
        <template slot="purchaserMemberName" slot-scope="row">
          {{ row.purchaserMemberPhone }} / {{ row.purchaserMemberName }}
        </template>
        <template slot="createTime" slot-scope="{ createTime }">
          {{ createTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <!-- 扣费类型：0项目套餐次数 1门票资产 2会员余额 3微信支付 4支付宝支付 -->
        <template
          slot="consumePrice"
          slot-scope="{ consumeMode, consumePrice }"
        >
          <template v-if="consumeMode === 0">{{ consumePrice }}</template>
          <template v-else>{{ consumePrice / 100 }}</template>
        </template>
        <span
          class="primary"
          slot="consumeStatusText"
          slot-scope="{ consumeStatusText }"
        >
          {{ consumeStatusText }}
        </span>
        <template slot="action" slot-scope="row">
          <a-button
            v-if="row.consumeStatus === 1"
            type="link"
            @click="applyCharge(row)"
            >申请反充</a-button
          >
          <a-button type="link" @click="toDetail(row)">详情</a-button>
        </template>
      </a-table>

      <!-- 分页 -->
      <footer class="flex-end p-2x">
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </footer>
    </section>
    <!-- 详情弹窗 和 申请反充弹窗 -->
    <record-detail
      :visible="visible"
      :id="rowId"
      :recharge-back="rechargeBack"
      @onOk="loadData"
      @onClose="onClose"
    ></record-detail>
  </div>
</template>

<script>
import RecordDetail from "./components/RecordDetail";
import moment from "moment";
import { dataDictionary, qmpChannel } from "@/api/setting";
import { productMerchantCategoryList } from "@/api/product";
import { findUserList } from "@/api/user";
import { orderProjectconsume } from "@/api/search";
import PanelHead from "../../../components/common/PanelHead.vue";

export default {
  name: "RecordList",
  components: { RecordDetail, PanelHead },
  data() {
    return {
      moment,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      searchForm: {
        productId: this.$store.state.consume.playItem.skuId, // 产品id
        playBeginTime: moment()
          .startOf("date")
          .valueOf(), // 游玩开始时间
        playEndTime: moment()
          .endOf("date")
          .valueOf(), // 游玩结束时间
        ticketNo: "", // 票号
        status: "", // 消费状态
        purchaserMemberId: "", // 会员号码
        consumeChannel: "", // 销售渠道
        projectConsume: false, // 项目套餐消费
        payConsume: false, // 付款码消费
        memberConsume: false, // 会员余额消费
        ticketConsume: "", // 门票资产消费
        operatorPersonIds: [], // 操作人
        page: 1, // 当前页码
        size: 10 // 每页显示条数
      },
      consumeStatusList: [], // 消费状态列表
      salesChannelList: [], // 消售渠道列表
      ticketConsumeList: [], // 门票资产消费列表
      operatorList: [], // 操作人列表
      searchVisible: false, // 是否显示高级查询
      tableData: [], // 表格数据
      buyChannel: [], // 销售渠道
      // 表格标题
      tableTitle: [
        {
          title: "门票",
          key: "productName",
          scopedSlots: { customRender: "productName" }
        },

        {
          title: "会员",
          key: "purchaserMemberName",
          scopedSlots: { customRender: "purchaserMemberName" }
        },
        {
          title: "游玩时间",
          key: "createTime",
          scopedSlots: { customRender: "createTime" }
        },
        {
          title: "消费数量",
          key: "consumePrice",
          scopedSlots: { customRender: "consumePrice" }
        },
        {
          title: "消费详情",
          dataIndex: "consumeWayInfo",
          key: "consumeWayInfo"
        },
        {
          title: "消费操作人",
          dataIndex: "consumeOperatorPersonName",
          key: "consumeOperatorPersonName"
        },
        {
          title: "消费渠道",
          dataIndex: "consumeChannelsText",
          key: "consumeChannelsText"
        },
        {
          title: "消费状态",
          dataIndex: "consumeStatusText",
          key: "consumeStatusText"
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      total: 0, // 总条数
      // 弹窗
      visible: false,
      rowId: 0, // 消费详情id
      rowItem: {}, // 表格行数据
      rechargeBack: false // 是否显示反充弹窗
    };
  },
  created() {
    this.loadKeys();
    this.loadSaleChannel();
    this.loadAssetsType();
    this.loadStaff();
    this.loadData();
  },
  methods: {
    // 加载字典数据
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,consumeStatus"
      })
        .then(res => {
          this.salesChannelList = res.sales_channel; // 销售渠道列表
          this.consumeStatusList = res.consumeStatus; // 消费状态列表
        })
        .catch(() => {});
    },

    // 加载销售渠道
    loadSaleChannel() {
      qmpChannel({
        whetherHaveSaleAuth: true
      })
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },

    // 加载门票资产消费列表
    loadAssetsType() {
      // 类型 1：门票，2：二销产品，3：资产类型，4:项目
      productMerchantCategoryList({ type: 3 })
        .then(res => {
          this.ticketConsumeList = res;
        })
        .catch(() => {});
    },

    // 加载操作人
    loadStaff() {
      findUserList()
        .then(res => {
          this.operatorList = res;
        })
        .catch(() => {});
    },

    // 加载消费记录列表
    loadData() {
      const params = { ...this.searchForm };
      params.operatorPersonIds = params.operatorPersonIds.join(",");
      params.projectConsume = this.searchForm.projectConsume ? 1 : 0;
      params.payConsume = this.searchForm.payConsume ? 1 : 0;
      params.memberConsume = this.searchForm.memberConsume ? 1 : 0;

      orderProjectconsume(params)
        .then(({ records, total }) => {
          this.total = total;
          this.tableData = [];
          this.tableData = JSON.parse(JSON.stringify(records));
        })
        .catch(() => {});
    },

    // 查询
    reload() {
      this.searchVisible = false;
      this.searchForm.page = 1;
      this.loadData();
    },

    // 重置表单
    reset(form) {
      this.$refs[form].resetFields();
      this.searchForm.playBeginTime = moment()
        .startOf("date")
        .valueOf();
      this.searchForm.playEndTime = moment()
        .endOf("date")
        .valueOf();
      this.reload();
    },

    // 日期改变
    dateChange([start, end]) {
      if (start && end) {
        this.searchForm.playBeginTime = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.playEndTime = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.playBeginTime = "";
        this.searchForm.playEndTime = "";
      }
    },

    // 条数改变
    sizeChange(size) {
      this.searchForm.size = size;
      this.reload();
    },

    // 打开详情
    toDetail(row) {
      this.rowId = row.id;
      this.visible = true;
    },

    // 申请反充弹窗
    applyCharge(row) {
      this.rowItem = row;
      this.rechargeBack = true;
      this.toDetail(row);
    },

    // 关闭弹窗
    onClose(type) {
      // 反充成功
      if (type === "success") {
        this.loadData();
      }
      this.visible = false;
      this.rechargeBack = false;
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 200px;
}
</style>
