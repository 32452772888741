var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper flex-between flex-col"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"flex-start align-center"},[_c('p',{staticClass:"font-xl "},[_vm._v("项目套餐")]),_c('span',{staticClass:"ml-4x mr-2x"},[_vm._v("套餐分类")]),_c('a-cascader',{attrs:{"options":_vm.saleCategoryList,"placeholder":"请选择","fieldNames":{
          label: 'name',
          value: 'id',
          children: 'children'
        }},on:{"change":_vm.onChange}})],1)]),_c('div',{staticClass:"main bg-white"},[_c('a-row',{attrs:{"gutter":[24, 24]}},[(_vm.tikectData.length)?_vm._l((_vm.tikectData),function(item,index){return _c('a-col',{key:index,attrs:{"xxl":6,"xl":8,"lg":12}},[_c('div',{staticClass:"ticket-card"},[_c('p',{staticClass:"card-title bold"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"mv-1x"},[_vm._v(" 可玩次数： "),(item.spuSpecInfo.whetherUseLimitType)?_c('span',[_vm._v(" "+_vm._s(item.spuSpecInfo ? item.spuSpecInfo.useLimit : "")+" 次 ")]):_c('span',[_vm._v(" 不限 ")])]),_c('div',{staticClass:"mb-2x"},[_vm._v(" 可玩项目： "),(item.spuSpecInfo.relationProjectType == 1)?_c('span',[_vm._v(" 全部 ")]):_vm._e(),(item.spuSpecInfo.relationProjectType == 2)?_c('span',[_vm._v(" "+_vm._s(_vm.relateList(item.projectInfoList).substr(0, 6))+" ")]):_vm._e(),(
                  item.spuSpecInfo.relationProjectType == 2 &&
                    item.projectInfoList
                )?_c('a-popover',{staticClass:"ml-x",attrs:{"trigger":"hover"}},[(item.projectInfoList)?_c('template',{slot:"content"},_vm._l((item.projectInfoList),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"ml-2x"},[_vm._v("可玩次数：")]),(
                        item.customRelation && item.customRelation.lessLimit
                      )?_c('span',[_vm._v(" 最少："+_vm._s(item.customRelation.lessLimit)+"次 ")]):_vm._e(),(
                        item.customRelation && item.customRelation.mostLimit
                      )?_c('span',[_vm._v(" 最多："+_vm._s(item.customRelation.mostLimit)+"次 ")]):_c('span',[_vm._v(" 不限 ")])])}),0):_vm._e(),_c('div',{staticClass:"more pointer",domProps:{"innerHTML":_vm._s('&sdot;&sdot;&sdot;')}})],2):_vm._e()],1),_c('div',{staticClass:"flex-between align-center"},[_c('span',{staticClass:"price-style primary-liner"},[_vm._v(_vm._s(_vm._f("money")(item.sellingPrice)))]),_c('div',{staticClass:"quantity-style flex-start"},[_c('div',{staticClass:"minus-style",on:{"click":function($event){return _vm.mathClick(false, index, item.count)}}},[_c('a-icon',{attrs:{"type":"minus"}})],1),_c('a-input',{staticClass:"num-style",on:{"keyup":function($event){return _vm.inputBlur(index, item.count)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}}),_c('div',{staticClass:"plus-style",on:{"click":function($event){return _vm.mathClick(true, index, item.count)}}},[_c('a-icon',{attrs:{"type":"plus"}})],1)],1)])])])}):_c('div',{staticClass:"flex-center res"},[_c('a-empty')],1)],2)],1),_c('div',{staticClass:"footer"},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('appPlayProjectPackageCheckout'),expression:"'appPlayProjectPackageCheckout'"}],staticClass:"btn-lg flex-between align-center",attrs:{"type":"primary","size":"large","shape":"round"},on:{"click":_vm.confirm}},[_c('span',[_vm._v("结账")]),_c('span',[_vm._v(_vm._s(_vm._f("money")(_vm.count)))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }