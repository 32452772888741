<template>
  <div class="wrapper">
    <!-- 头部 -->
    <header>
      <panel-head title="未结算记录" :close="false"></panel-head>
    </header>
    <div class="record">
      <div class="recordSearch">
        <div class="recordSearchName">票码</div>
        <a-input class="recordSearchInput" size="large" allow-clear>
          <a-icon type="scan" slot="suffix" />
        </a-input>
        <a-button type="primary" @click="reload">
          查询
        </a-button>
      </div>
      <!-- 表格 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        row-key="id"
        :pagination="false"
      >
        <template slot="playBeginTime" slot-scope="{ playBeginTime }">
          {{ playBeginTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot="now" slot-scope="{ now }">
          {{ now | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot="action" slot-scope="row">
          <span class="pointer blue" @click="madefinished(row)">确定离场</span>
        </template>
      </a-table>
      <!-- 分页 -->
      <footer class="flex-end mt-2x">
        <a-pagination
          v-model="page"
          :default-page-size="size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="getUnfinsisedList"
          @showSizeChange="sizeChange"
        />
      </footer>
      <div class="recordBottom mt-2x">
        <a-button
          class="recordBottomItem"
          type="primary"
          @click="allMadefinished"
          v-if="this.data.length"
        >
          确认清场
        </a-button>
        <a-button class="recordBottomItem" @click="$router.back()">
          返回
        </a-button>
      </div>
    </div>
    <!-- 输入会员支付密码弹窗 -->
    <a-modal
      v-model="passwordFormVisible"
      title="请输入会员支付密码"
      @ok="onOk"
      @cancel="resetPasswordForm"
    >
      <a-form-model
        ref="passwordForm"
        :model="passwordForm"
        :rules="passwordFormRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        label-align="left"
      >
        <a-form-model-item label="会员姓名" :colon="false">
          <span>{{ memberInfo.name }}</span>
        </a-form-model-item>
        <a-form-model-item label="会员手机号" :colon="false">
          <span>{{ memberInfo.mobile }}</span>
        </a-form-model-item>
        <a-form-model-item label="支付密码" prop="password" :colon="false">
          <a-input-password
            v-model="passwordForm.password"
            placeholder="请输入6位纯数字支付密码"
            :max-length="6"
            allow-clear
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import { mapState } from "vuex";
import {
  itemwriteoffUnfinsised,
  itemwriteoffMadefinished,
  itemwriteoffAllmadefinished,
  itemConsumerMember
} from "@/api/order";

// 未结算记录表头
const columns = [
  {
    title: "门票",
    dataIndex: "detailInfo",
    key: "detailInfo"
  },
  {
    title: "开始时间",
    key: "playBeginTime",
    scopedSlots: { customRender: "playBeginTime" }
  },
  {
    title: "收取押金",
    dataIndex: "depositInfo",
    key: "depositInfo"
  },
  {
    title: "当前时间",
    key: "now",
    scopedSlots: { customRender: "now" }
  },
  {
    title: "游玩时长（分钟）",
    dataIndex: "playTime",
    key: "playTime"
  },
  {
    title: "应收",
    dataIndex: "receivable",
    key: "receivable"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  components: { PanelHead },
  name: "DeductionTimingRecord",
  data() {
    // 自定义校验设置支付密码
    let checkPassword = (rule, value, callback) => {
      if (value !== "") {
        if (!/^[0-9]{6}$/.test(value)) {
          callback(new Error("请输入6位纯数字支付密码"));
        } else {
          callback();
        }
      }
    };

    return {
      consumeNum: "", // 票号
      columns, // 未结算记录表头
      data: [], // 未结算记录列表
      page: 1, // 当前页码
      size: 10, // 每页显示条数
      total: 0, // 总条数
      passwordFormVisible: false, // 是否显示输入会员支付密码弹窗
      passwordForm: {
        password: ""
      },
      passwordFormRules: {
        password: [{ validator: checkPassword, trigger: "blur" }]
      },
      recordId: "", // 消费记录数据id
      memberPwd: "", // 会员密码
      finishType: "", // 离场类型
      memberInfo: {} // 会员信息
    };
  },
  computed: {
    ...mapState("consume", ["playItem"])
  },
  created() {
    this.consumeNum = this.$route.query.consumeNum; // 票号
    this.getUnfinsisedList(); // 查询未结算列表
  },
  methods: {
    // 通过项目消费编号获取会员信息
    getMemberInfo() {
      itemConsumerMember({ consumeNumber: this.num })
        .then(res => {
          this.memberInfo = res;
        })
        .catch(() => {});
    },

    // 查询未结算列表
    getUnfinsisedList() {
      itemwriteoffUnfinsised({
        itemSkuId: this.playItem.skuId, // 项目skuId
        itemSpuId: this.playItem.spuId, // 项目spuId
        consumeNumber: this.consumeNum, // 票号
        page: this.page, // 当前页码
        size: this.size // 每页显示的条数
      })
        .then(res => {
          this.data = res.records; // 未结算列表
          this.total = res.total; // 总条数
        })
        .catch(() => {});
    },

    // 查询
    reload() {
      this.page = 1;
      this.getUnfinsisedList();
    },

    // 条数改变
    sizeChange(size) {
      this.size = size;
      this.reload();
    },

    // 离场
    madefinished(row) {
      this.recordId = row.id; // 消费记录数据id
      this.$confirm({
        title: "确定离场",
        content: "确定要离场吗？",
        onOk: () => {
          itemwriteoffMadefinished({
            id: row.id, // 消费记录数据id
            itemSkuId: this.playItem.skuId, // 项目skuId
            itemSpuId: this.playItem.spuId // 项目spuId
          })
            .then(() => {
              this.reload();
              this.$message.success("离场成功！");
            })
            .catch(err => {
              if (err.status === "MEMBER10140") {
                this.getMemberInfo();
                this.passwordFormVisible = true;
              }
            });
        },
        onCancel() {}
      });
    },

    // 清场
    allMadefinished() {
      this.$confirm({
        title: "确定清场",
        content: "确定要清场吗？",
        onOk: () => {
          itemwriteoffAllmadefinished({
            itemSkuId: this.playItem.skuId, // 项目skuId
            itemSpuId: this.playItem.spuId // 项目spuId
          })
            .then(() => {
              this.reload();
              this.$message.success("清场成功！");
            })
            .catch(err => {
              if (err.status === "MEMBER10140") {
                this.getMemberInfo();
                this.passwordFormVisible = true;
              }
            });
        },
        onCancel() {}
      });
    },

    // 确定提交
    onOk() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          // 如果是会员码消费，则需要输入会员密码
          if (this.passwordForm.password !== "") {
            this.memberPwd = this.passwordForm.password; // 会员密码
          }
          if (this.finishType === "leave") {
            itemwriteoffMadefinished({
              id: this.recordId, // 消费记录数据id
              itemSkuId: this.playItem.skuId, // 项目skuId
              itemSpuId: this.playItem.spuId, // 项目spuId
              memberPwd: this.memberPwd // 会员密码
            })
              .then(() => {
                this.passwordFormVisible = false;
                this.reload();
                this.$message.success("离场成功！");
              })
              .catch(() => {});
          } else if (this.finishType === "clear") {
            itemwriteoffAllmadefinished({
              itemSkuId: this.playItem.skuId, // 项目skuId
              itemSpuId: this.playItem.spuId, // 项目spuId
              memberPwd: this.memberPwd // 会员密码
            })
              .then(() => {
                this.passwordFormVisible = false;
                this.reload();
                this.$message.success("清场成功！");
              })
              .catch(() => {});
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    // 重置会员支付密码表单
    resetPasswordForm() {
      this.$refs.passwordForm.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.record {
  height: calc(100% - 54px);
  display: flex;
  flex-direction: column;
  .recordSearch {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    .recordSearchName {
      width: 50px;
    }
    .recordSearchInput {
      width: 310px;
      margin-right: 25px;
    }
    .recordSearchInput /deep/ .ant-input {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .recordBottom {
    height: 180px;
    display: flex;
    justify-content: flex-end;
    .recordBottomItem {
      margin-right: 30px;
    }
  }
}
</style>
