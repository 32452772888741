<template>
  <div class="summary-today flex-col flex-start wrapper">
    <!-- 头部 -->
    <panel-head title="消费汇总"></panel-head>
    <!-- 主体 -->
    <div class="main flex-start mt-2x">
      <div class="consume-statistics flex-shrink bd-r">
        <p class="mb-2x">消费日期：{{ startDate | date }}</p>
        <p class="mb-2x">所属项目：{{ playItem.name }}</p>
        <p>本日接待人次：{{ receptionNum || 0 }}</p>
      </div>
      <a-tabs
        type="card"
        class="flex-grow"
        default-active-key="1"
        @change="changeTab"
      >
        <a-tab-pane key="1" tab="消费类型汇总">
          <!-- 消费类型汇总表格 -->
          <a-table
            class="ph-1x"
            :columns="consumeTypeTitle"
            :data-source="consumeTypeList"
            :pagination="false"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="count" slot-scope="row">
              {{ row / 100 }}
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="项目套餐消费汇总">
          <!-- 项目套餐消费汇总表格 -->
          <a-table
            class="ph-1x"
            :columns="packageConsumeTitle"
            :data-source="packageConsumeList"
            :pagination="false"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        type="primary"
        ghost
        shape="round"
        class="btn-lg mr-3x"
        size="large"
        @click="goSummaryTotal"
        >查看消费汇总</a-button
      >
      <a-button type="primary" class="btn-lg" size="large" shape="round"
        >打印消费汇总</a-button
      >
    </div>
  </div>
</template>

<script>
import PanelHead from "@/components/common/PanelHead";
import moment from "moment";
import { mapState } from "vuex";
import {
  getItemwriteoffCount,
  getItemwriteoffConsumesummary,
  getItemwriteoffSynthesizeitemsummary
} from "@/api/order.js"; // MS-畅游宝订单中心接口

// 消费类型汇总表头
const consumeTypeTitle = [
  {
    title: "消费类型",
    dataIndex: "type"
  },
  {
    title: "消费数量",
    dataIndex: "consumeCount",
    scopedSlots: { customRender: "count" }
  },
  {
    title: "反充数量",
    dataIndex: "backconsumeCount",
    scopedSlots: { customRender: "count" }
  },
  {
    title: "数量小计",
    dataIndex: "subtotal",
    scopedSlots: { customRender: "count" }
  }
];

// 项目套餐消费汇总表头
const packageConsumeTitle = [
  {
    title: "产品来源",
    dataIndex: "orderSourceTypeText"
  },
  {
    title: "产品名称",
    dataIndex: "type"
  },
  {
    title: "产品分类",
    dataIndex: "productCategoryIdText"
  },
  {
    title: "消费数量",
    dataIndex: "consumeCount"
  },
  {
    title: "反充数量",
    dataIndex: "backconsumeCount"
  },
  {
    title: "数量小计",
    dataIndex: "subtotal"
  }
];

export default {
  name: "SummaryToday",
  components: {
    PanelHead
  },
  data() {
    return {
      startDate: moment()
        .startOf("date")
        .valueOf(), // 开始日期
      endDate: moment()
        .endOf("date")
        .valueOf(), // 结束日期
      consumeTypeTitle, // 项目套餐消费汇总表头
      consumeTypeList: [], // 项目套餐消费汇总列表
      receptionNum: 0, // 本日接待人数
      packageConsumeTitle, // 项目套餐消费汇总表头
      packageConsumeList: [] // 项目套餐消费汇总列表
    };
  },
  computed: mapState("consume", ["playItem"]),
  created() {
    this.getConsumeTypeList(); // 查询消费类型汇总列表
    this.getReceptionNum(); // 查询本日接待人次
    this.getPackageConsumeList(); // 查询项目套餐消费汇总列表
  },
  methods: {
    // 查询消费类型汇总列表
    getConsumeTypeList() {
      getItemwriteoffConsumesummary({
        itemId: this.playItem.skuId // 项目id
      })
        .then(res => {
          this.consumeTypeList = res || [];
        })
        .catch(() => {});
    },

    // 查询本日接待人次
    getReceptionNum() {
      getItemwriteoffCount({
        productId: this.playItem.skuId // 项目id
      }).then(res => {
        this.receptionNum = res;
      });
    },

    // 查询项目套餐消费汇总列表
    getPackageConsumeList() {
      getItemwriteoffSynthesizeitemsummary({
        itemId: this.playItem.skuId // 项目id
      })
        .then(res => {
          this.packageConsumeList = res || [];
          // 表格增加一行：合计
          if (this.packageConsumeList.length) {
            this.addRowCalc(res);
          }
        })
        .catch(() => {});
    },

    // 表格增加一行：合计
    addRowCalc(res) {
      let obj = {
        orderSourceTypeText: "",
        type: "合计",
        consumeCount: 0,
        backconsumeCount: 0,
        subtotal: 0
      };
      res.forEach(item => {
        obj.consumeCount += item.consumeCount;
        obj.backconsumeCount += item.backconsumeCount;
        obj.subtotal += item.subtotal;
      });
      this.packageConsumeList.push(obj);
    },

    // tab切换
    changeTab(key) {
      switch (key) {
        case "1":
          this.getConsumeTypeList(); // 根据项目汇总基础id查询消费类型汇总列表
          break;
        case "2":
          this.getPackageConsumeList(); // 根据项目汇总基础id查询项目套餐消费汇总列表
          break;
        default:
          this.$message.warning("出现未知错误，请联系管理员");
          break;
      }
    },

    // 跳转到消费汇总列表
    goSummaryTotal() {
      this.$router.push({
        path: "/play/summary/SummaryTotal"
      });
    }
  }
};
</script>

<style lang="less" scoped>
.summary-today {
  .main {
    height: 100%;
    .consume-statistics {
      width: 230px;
      height: 100%;
      padding: 16px;
    }
  }
}

.input {
  width: 200px;
}
</style>
