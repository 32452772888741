<template>
  <div class="wrapper flex-between flex-col">
    <!-- 级联菜单 -->
    <div class="header">
      <div class="flex-start align-center">
        <p class="font-xl ">项目套餐</p>
        <span class="ml-4x mr-2x">套餐分类</span>
        <a-cascader
          :options="saleCategoryList"
          placeholder="请选择"
          @change="onChange"
          class=""
          :fieldNames="{
            label: 'name',
            value: 'id',
            children: 'children'
          }"
        />
      </div>
    </div>
    <!-- 项目套餐 -->
    <div class="main bg-white">
      <a-row :gutter="[24, 24]">
        <template v-if="tikectData.length">
          <a-col
            :xxl="6"
            :xl="8"
            :lg="12"
            v-for="(item, index) in tikectData"
            :key="index"
          >
            <div class="ticket-card">
              <p class="card-title bold">{{ item.name }}</p>
              <p class="mv-1x">
                可玩次数：
                <span v-if="item.spuSpecInfo.whetherUseLimitType">
                  {{ item.spuSpecInfo ? item.spuSpecInfo.useLimit : "" }}
                  次
                </span>
                <span v-else> 不限 </span>
              </p>
              <div class="mb-2x">
                可玩项目：
                <span v-if="item.spuSpecInfo.relationProjectType == 1">
                  全部
                </span>
                <span v-if="item.spuSpecInfo.relationProjectType == 2">
                  {{ relateList(item.projectInfoList).substr(0, 6) }}
                </span>
                <a-popover
                  trigger="hover"
                  class="ml-x"
                  v-if="
                    item.spuSpecInfo.relationProjectType == 2 &&
                      item.projectInfoList
                  "
                >
                  <template slot="content" v-if="item.projectInfoList">
                    <p
                      v-for="(item, index) in item.projectInfoList"
                      :key="index"
                    >
                      {{ item.name }}
                      <span class="ml-2x">可玩次数：</span>
                      <span
                        v-if="
                          item.customRelation && item.customRelation.lessLimit
                        "
                      >
                        最少：{{ item.customRelation.lessLimit }}次
                      </span>
                      <span
                        v-if="
                          item.customRelation && item.customRelation.mostLimit
                        "
                      >
                        最多：{{ item.customRelation.mostLimit }}次
                      </span>
                      <span v-else> 不限 </span>
                    </p>
                  </template>
                  <div class="more pointer" v-html="'&sdot;&sdot;&sdot;'"></div>
                </a-popover>
              </div>
              <!-- 计算器 -->
              <div class="flex-between align-center">
                <span class="price-style primary-liner">{{
                  item.sellingPrice | money
                }}</span>
                <!-- 步进器 -->
                <div class="quantity-style flex-start">
                  <div
                    class="minus-style"
                    @click="mathClick(false, index, item.count)"
                  >
                    <a-icon type="minus" />
                  </div>
                  <a-input
                    class="num-style"
                    v-model="item.count"
                    @keyup="inputBlur(index, item.count)"
                  />
                  <div
                    class="plus-style"
                    @click="mathClick(true, index, item.count)"
                  >
                    <a-icon type="plus" />
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </template>
        <div v-else class="flex-center res">
          <a-empty />
        </div>
      </a-row>
    </div>

    <!-- 底部结账 -->
    <div class="footer">
      <a-button
        v-permission="'appPlayProjectPackageCheckout'"
        type="primary"
        size="large"
        shape="round"
        class="btn-lg flex-between align-center"
        @click="confirm"
      >
        <span>结账</span>
        <span>{{ count | money }}</span>
      </a-button>
    </div>
  </div>
</template>

<script>
import { productMerchantCategoryList } from "../../../api/product";
import { productFrontRechargeticket } from "../../../api/search";
import { createCart } from "@/api/cart";
import { mapActions } from "vuex";
import { listToTree } from "../../../utils/global";

export default {
  name: "ProjectPackage",
  data() {
    return {
      options: [],
      totalMoney: "", // 所有应收总额
      tikectData: [],
      saleCategoryList: [], // 二销产品分类列表
      carId: "" // 购物车订单id
    };
  },
  created() {
    this.getSaleCategoryList(); // 获取二销产品分类列表
    this.getData();
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    // 获取二销产品分类列表
    getSaleCategoryList() {
      // 类型 10游乐产品分类
      productMerchantCategoryList({ type: 10 })
        .then(res => {
          this.renderOption(res);
        })
        .catch(() => {});
    },
    // 渲染级联的数据
    renderOption(res) {
      this.saleCategoryList = listToTree(res);
    },
    // 可玩项目字符截取
    relateList(params) {
      const arr = [];
      if (params) {
        params.forEach(item => {
          arr.push(item.name);
        });
      }
      return arr.toString();
    },
    getData(params = {}) {
      productFrontRechargeticket({
        packageType: 2, // 充值套餐类型 1: 门票资产套餐 2: 项目套餐
        page: 1,
        size: 9999,
        ...params
      })
        .then(res => {
          this.tikectData = res.records;
        })
        .catch(() => {});
    },
    // 级联菜单 改变
    onChange(list) {
      this.getData({ categoryId: list[list.length - 1] });
    },
    //用户输入数值
    inputBlur(index, value) {
      if (!/(^[0-9]\d*$)/.test(value)) {
        this.tikectData[index].count = 0;
        this.$message.warning("输入的数字为整数");
        return;
      }
    },
    //点击加减  true=+  false=-
    mathClick(bl, index, count) {
      if (bl) {
        this.tikectData[index].count = parseInt(count) + 1;
      } else {
        count = parseInt(count) - 1;
        this.tikectData[index].count = count >= 0 ? count : 0;
      }
    },
    // 确定按钮
    confirm() {
      let { tikectData } = this;
      let submitList = tikectData.filter(item => {
        item.totalMoney = this.count;
        item.salePrice = item.sellingPrice;
        return item.count;
      });
      if (!submitList.length)
        return this.$message.warning("你还未选择套餐和套餐数量");
      const params = {
        buyChannels: 6, // 购买渠道
        businessCategory: 1, // 订单业务类型
        cartProductList: []
      };
      submitList.forEach(item => {
        params.cartProductList.push({
          productId: item.id,
          spuId: item.spuId,
          buyNum: item.count
        });
      });
      createCart(params).then(data => {
        this.setEndOfPayConfig({
          backUrl: "/play/package/projectPackage",
          logoutMember: true
        });
        this.$store.dispatch("order/setCartList", { id: data.id });
        this.$router.push({ path: "/home/sell/OrderInfo" });
      });
    }
  },
  computed: {
    count() {
      let { tikectData } = this;
      let count = 0;
      tikectData.forEach(item => {
        count += item.count * item.sellingPrice;
      });
      return count;
    }
  }
};
</script>

<style lang="less" scoped>
/*中部*/
.main {
  padding: 16px;
  margin-top: 16px;
  .ticket-card {
    height: 191px;
    padding: 16px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    background-color: #f4f9fc;
    .card-title {
      font-size: 28px;
    }
    .more {
      display: inline-block;
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      font-size: 20px;
      background: #c8ccce;
      color: #fff;
      border-radius: 50%;
    }
  }
}

// 价格样式
.price-style {
  font-size: 28px;
  font-weight: bold;
}
</style>
