<template>
  <div class="wrapper flex-between flex-col">
    <!--选择项目-->
    <header class="tab-title">
      <div class="font-xl" @click="showItems = !showItems">
        <span>选择项目</span>
        <p class="down pointer gray">
          <span class="mr-2x font-l text-v">{{ playItem.name }}</span>
          <a-icon class="text-v" type="down" v-show="showItems" />
          <a-icon class="text-v" type="up" v-show="!showItems" />
        </p>
      </div>

      <div class="pt-3x" v-show="showItems">
        <a-row v-show="showItems" :gutter="[16, 16]">
          <a-col
            :md="6"
            :lg="5"
            :xl="4"
            :xxl="3"
            v-for="item in projectList"
            :key="item.spuId"
            @click="selectProject(item)"
          >
            <div
              class="project flex-col flex-center"
              :class="{ active: item.spuId === playItem.spuId }"
            >
              <p class="big text-1">
                {{ item.name }}
              </p>
              <p class="mt-2x" v-show="item.deductionType === 0">按次扣费</p>
              <p class="mt-2x" v-show="item.deductionType === 1">计时扣费</p>
              <p class="mt-2x" v-show="item.deductionType === 2">手工扣费</p>
            </div>
          </a-col>
        </a-row>
      </div>

      <a-empty
        description="当前商户没有可用项目"
        v-show="projectList.length === 0"
      />
    </header>

    <!-- 如果没有选择项目 -->
    <div class="empty flex-grow" v-if="Object.keys(playItem).length === 0">
      <span>请先选择需要消费的项目</span>
    </div>
    <!--选择了项目扣费内容-->
    <section
      class="main mt-2x relative"
      v-if="Object.keys(playItem).length > 0"
    >
      <div class="tip">
        {{ playItem.deductionType === 1 ? "开始计时" : "项目扣费" }}
      </div>

      <!-- 键盘 -->
      <div class="keyword">
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
          <a-form-model-item
            label=""
            prop="consumerPrice"
            v-if="playItem.deductionType === 2"
          >
            <a-input
              class="prefix-input"
              v-model="ruleForm.consumerPrice"
              placeholder="请输入本次消费单价"
            >
              <span slot="prefix" class="primary font-xl">本次消费单价</span>
            </a-input>
          </a-form-model-item>
        </a-form-model>
        <keyboard-pro type="2" v-model="num" @ok="keyboardClick">
          <template v-slot:input="{ confirm }">
            <a-form-model autocomplete="off">
              <a-input
                size="large"
                :placeholder="placeholder[playItem.deductionType]"
                v-model="num"
                allow-clear
                @pressEnter="confirm()"
              >
                <a-icon slot="prefix" class="primary" type="search" />
                <a-icon type="scan" class="primary" slot="suffix" />
              </a-input>
            </a-form-model>
          </template>
        </keyboard-pro>
      </div>
    </section>

    <!-- 底部 -->
    <footer class="footer" v-if="Object.keys(playItem).length > 0">
      <div>
        <!-- <a-button type="primary" ghost size="large">排队取号</a-button>
        <a-button type="primary" ghost class="mh-2x" size="large"
          >叫号游玩</a-button
        > -->
        <a-button
          class="btn-lg"
          type="primary"
          size="large"
          shape="round"
          ghost
          @click="$router.push('/play/record/RecordList')"
          >消费记录</a-button
        >
        <a-button
          class="btn-lg mh-3x"
          type="primary"
          size="large"
          shape="round"
          ghost
          @click="$router.push('/play/summary/SummaryToday')"
          >消费汇总</a-button
        >
        <a-button
          v-if="playItem.deductionType === 1"
          class="btn-lg"
          type="primary"
          size="large"
          shape="round"
          @click="toOff"
          >结束计时</a-button
        >
      </div>
      <!-- <div>
        <span>本日接待人次：<span class="red">300</span></span>
      </div> -->
    </footer>
    <!-- 输入会员支付密码弹窗 -->
    <a-modal
      v-model="passwordFormVisible"
      title="请输入会员支付密码"
      @ok="onOk"
      @cancel="resetPasswordForm"
    >
      <a-form-model
        ref="passwordForm"
        :model="passwordForm"
        :rules="passwordFormRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        label-align="left"
      >
        <a-form-model-item label="会员姓名" :colon="false">
          <span>{{ memberInfo.name }}</span>
        </a-form-model-item>
        <a-form-model-item label="会员手机号" :colon="false">
          <span>{{ memberInfo.mobile }}</span>
        </a-form-model-item>
        <a-form-model-item label="支付密码" prop="password" :colon="false">
          <a-input-password
            v-model="passwordForm.password"
            placeholder="请输入6位纯数字支付密码"
            :max-length="6"
            allow-clear
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import KeyboardPro from "../../../components/common/KeyboardPro";
import { mapState } from "vuex";
import { RechargeMoney, dateFormat } from "../../../utils/global";
import { itemWriteoff, itemConsumerMember } from "@/api/order";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
import { merchantProject } from "../../../api/product";
import { scanCode, clearScanCode } from "../../../utils/hardware";

export default {
  name: "DeductionIndex",
  components: { KeyboardPro },
  data() {
    // 自定义校验设置支付密码
    let checkPassword = (rule, value, callback) => {
      if (value !== "") {
        if (!/^[0-9]{6}$/.test(value)) {
          callback(new Error("请输入6位纯数字支付密码"));
        } else {
          callback();
        }
      }
    };

    return {
      num: "", // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
      placeholder: {
        0: "票号/卡或腕带号/会员编号/付款码",
        1: "票号/卡或腕带号/会员编号/付款码",
        2: "票号/卡或腕带号"
      },
      type: 0, //扣费类型 0 按次 1 计时 2 手工
      ruleForm: {
        consumerPrice: ""
      },
      rules: {
        consumerPrice: [
          { required: true, message: "请输入本次消费单价", trigger: "blur" },
          { validator: RechargeMoney, trigger: "blur" }
        ]
      },
      passwordFormVisible: false, // 是否显示输入会员支付密码弹窗
      passwordForm: {
        password: ""
      },
      passwordFormRules: {
        password: [{ validator: checkPassword, trigger: "blur" }]
      },
      projectList: [], //项目列表
      showItems: true, //显示选择项目
      memberInfo: {} // 会员信息
    };
  },
  computed: {
    ...mapState("consume", ["playItem"])
  },
  created() {
    this.getProjectList();
  },

  mounted() {
    scanCode(this.scanCode);
  },

  beforeDestroy() {
    clearScanCode();
  },

  methods: {
    getProjectList() {
      //获取当前商户的项目列表
      merchantProject()
        .then(res => {
          this.projectList = res;
        })
        .catch(() => {});
    },
    selectProject(row) {
      this.$store.commit("consume/updatePlayItem", row);
    },
    scanCode(res) {
      this.num = res;
      this.keyboardClick();
    },
    // 通过项目消费编号获取会员信息
    getMemberInfo() {
      itemConsumerMember({ consumeNumber: this.num })
        .then(res => {
          this.memberInfo = res;
        })
        .catch(() => {});
    },
    keyboardClick() {
      let memberNumReg = /^1\d{11}$/; // 校验是否为会员号
      if (memberNumReg.test(this.num)) {
        this.getMemberInfo();
        this.passwordFormVisible = true;
      }
      let bl = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid && this.num) {
          if (bl) {
            let { playItem, ruleForm, num } = this;
            let params = {
              consumeChannels: "6", // 窗口消费渠道
              num: num, // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
              itemName: playItem.name, // 项目名称
              itemSkuId: playItem.skuId, // 项目skuId
              itemSpuId: playItem.spuId // 项目spuId
            };

            // 扣费类型(deductionType)：0按次扣费 1计时扣费 2手工扣费
            if (playItem.deductionType === 1) {
              params.timeType = 0; // 计时类型：开始0 结束1
            } else if (playItem.deductionType === 2) {
              params.consumerPrice = ruleForm.consumerPrice * 100; // 消费金额
            }
            itemWriteoff(params, { catch: false })
              .then(res => {
                HardwareService.speak(`消费${res.title}`);
                let obj = {
                  ticketInfo: "门票",
                  memberInfo: "会员",
                  depositInfo: "押金",
                  beginTime: "开始时间",
                  entTime: "结束时间",
                  playDuration: "游玩时长",
                  receivable: "应收",
                  receivableInfo: "收费详情",
                  assets: "剩余"
                };
                let getNode = data => {
                  let arr = [];
                  for (const key in data) {
                    if (obj[key] && data[key] !== null) {
                      arr.push(
                        <p class="mt-1x">
                          {obj[key]}：
                          {dateFormat(data[key], "YYYY-MM-DD hh:mm:ss")}
                        </p>
                      );
                    }
                  }
                  return <div>{...arr}</div>;
                };
                this.$notification.success({
                  top: "60px",
                  message: res.title,
                  description: getNode(res)
                });
                // 重置部分表单
                this.num = "";
                this.ruleForm.consumerPrice = "";
                this.passwordForm.password = "";
              })
              .catch(err => {
                let errMsg = ""; // 错误消息
                if (err.status === "MEMBER10140") {
                  this.getMemberInfo();
                  this.passwordFormVisible = true;
                  errMsg = err.message;
                  HardwareService.speak(`${errMsg}`);
                } else {
                  errMsg =
                    err.message.indexOf(",") > 0
                      ? err.message.substring(0, err.message.indexOf(","))
                      : err.message;
                  HardwareService.speak(`消费失败，${errMsg}`);
                }
                this.$notification.error({
                  top: "60px",
                  message: `消费失败`,
                  description: h => {
                    const arr = [h("p", { class: "mt-1x" }, `${errMsg}`)];
                    return h("div", arr);
                  }
                });
              });
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    // 确定提交
    onOk(val) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let testReg = /^(?!\s)[a-zA-Z0-9\u4e00-\u9fa5`~!@%^*()_\\/\-+=<>?:"{}|,.;'[\]·！￥¥（）—《》？：“”【】、；‘，。\n\s]+$/;
          if (!val) {
            this.$message.error(
              `请扫描或输入${this.placeholder[this.playItem.deductionType]}`
            );
          } else if (testReg.test(val)) {
            const params = {
              consumeChannels: "6", // 口消费渠道
              num: this.num, // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
              itemName: this.playItem.name, // 项目名称
              itemSkuId: this.playItem.skuId, // 项目skuId
              itemSpuId: this.playItem.spuId // 项目spuId
            };
            // 如果是计时项目，则开始计时
            if (this.playItem.deductionType === 1) {
              params.timeType = 0;
            }
            // 如果是手工扣费项目，则添加消费价格
            if (this.playItem.deductionType === 2) {
              params.consumerPrice = this.ruleForm.consumerPrice * 100;
            }
            // 如果是会员码消费，则需要输入会员密码
            if (this.passwordForm.password !== "") {
              params.memberPw = this.passwordForm.password; // 会员密码
            }
            itemWriteoff(params, { catch: false })
              .then(res => {
                this.passwordFormVisible = false;
                HardwareService.speak(`消费${res.title}`);
                let obj = {
                  ticketInfo: "门票",
                  memberInfo: "会员",
                  depositInfo: "押金",
                  beginTime: "开始时间",
                  entTime: "结束时间",
                  playDuration: "游玩时长",
                  receivable: "应收",
                  receivableInfo: "收费详情",
                  assets: "剩余"
                };
                let getNode = data => {
                  let arr = [];
                  for (const key in data) {
                    if (obj[key] && data[key] !== null) {
                      arr.push(
                        <p class="mt-1x">
                          {obj[key]}：
                          {dateFormat(data[key], "YYYY-MM-DD hh:mm:ss")}
                        </p>
                      );
                    }
                  }
                  return <div>{...arr}</div>;
                };
                this.$notification.success({
                  top: "60px",
                  message: res.title,
                  description: getNode(res)
                });
                // 重置部分表单
                this.num = "";
                this.ruleForm.consumerPrice = "";
                this.passwordForm.password = "";
              })
              .catch(err => {
                let errMsg = ""; // 错误消息
                if (err.status === "MEMBER10140") {
                  this.getMemberInfo();
                  this.passwordFormVisible = true;
                  errMsg = err.message;
                  HardwareService.speak(`${errMsg}`);
                } else {
                  errMsg =
                    err.message.indexOf(",") > 0
                      ? err.message.substring(0, err.message.indexOf(","))
                      : err.message;
                  HardwareService.speak(`消费失败，${errMsg}`);
                }
                this.$notification.error({
                  top: "60px",
                  message: `消费失败`,
                  description: h => {
                    const arr = [h("p", { class: "mt-1x" }, `${errMsg}`)];
                    return h("div", arr);
                  }
                });
              });
          } else {
            this.$message.error(
              `${
                this.placeholder[this.playItem.deductionType]
              }不能以空格开头且不能包含表情符、$、&、#等特殊符号`
            );
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    // 重置会员支付密码表单
    resetPasswordForm() {
      this.$refs.passwordForm.resetFields();
    },

    // 结束计时
    toOff() {
      this.$router.push({
        path: "/play/deduction/DeductionTimingOff"
      });
    }
  }
};
</script>

<style lang="less" scoped>
.tab-title {
  padding: 16px;
  border-radius: 5px;
  background-color: white;
  .down {
    float: right;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
  }

  .project {
    height: 120px;
    border: 2px solid #45b1fb;
    border-radius: 10px;
    box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
    text-align: center;
    font-weight: bold;
    color: #45b1fb;
    cursor: pointer;
    .big {
      font-size: 20px;
      font-weight: bold;
    }
    .small {
      margin-top: 16px;
    }
    &.active {
      border: none;
      box-shadow: 3px 0 9px 0 rgba(116, 116, 116, 0.26);
      border-radius: 10px;
      background: linear-gradient(181deg, #44b8fb, #4d8afb);
      color: #fff;
    }
  }
}
.empty {
  color: #ccc;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  padding-top: 40px;
  .keyword {
    width: 532px;
    margin: 0 auto;
  }
  .tip {
    position: absolute;
    left: 16px;
    top: 16px;
    font-size: 18px;
  }
}
</style>
